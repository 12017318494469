





















































import { Component, Vue } from 'vue-property-decorator'
import SelectStore from '@/components/select-branch.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import ProductProvider from '@/resources/product.provider'
import ExchangeProductList from '@/components/desktop-pos/exchange-product-list.vue'
import { mapProduct } from '@/utils/product.utils'
import DesktopPosNavbar from '@/components/desktop-pos/navbar.vue'

const ProductService = new ProductProvider()

@Component({
  components: {
    ExchangeProductList,
    SelectStore,
    DesktopPosNavbar
  },
  computed: {
    ...mapState({
      selectBranch: (state: any) => state.Store.selectBranch,
      storeInfo: (state: any) => state.Store.storeInfo,
      selectPos: (state: any) => state.Store.selectPos
    }),
    ...mapGetters({
      cartAmount: 'ExchangeOrder/cartAmount'
    })
  },
  methods: {
    ...mapActions({
      addToCart: 'ExchangeOrder/addToCart'
    })
  }
})
export default class ExchangeSearchProduct extends Vue {
  search = ''

  loading = false

  storeInfo!: any

  cartAmount!: any

  selectBranch!: any

  selectPos!: any

  products: any[] = []

  addToCart!: any;

  mounted (): void {
    if (this.searchQuery !== '') {
      this.search = `${this.searchQuery}`
      this.searchProduct()
    }
  }

   addItem (item: any): void {
    this.addToCart({ ...item, operation: 'new_item' })
  }

  get storeName (): string {
    return this.storeInfo?.name || 'STORE NOT FOUND'
  }

  get warehouseName (): string {
    if (!this.selectBranch || this.selectBranch === null) {
      return ''
    }
    return this.selectBranch.warehouse.name
  }

  get searchQuery (): string | (string | null)[] {
    return this.$route.query.search || ''
  }

  get focusQuery (): string | (string | null)[] {
    return this.$route.query.focus || ''
  }

  async searchProduct (): Promise<void> {
    this.$router.push({ name: 'ExchangeSearchProduct', params: this.$route.params, query: { search: this.search } })
    this.loading = true
    const { data: { results } } = await ProductService.getProduct({ search: this.search })

      this.products = mapProduct(results, this.selectBranch, this.search)
    if (this.products.length === 0) {
      this.$buefy.toast.open({ message: 'Product not found', type: 'is-danger' })
    }
    this.loading = false
  }
}
